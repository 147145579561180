<section class="page_404">
    <!--dust particel-->
    <div>
        <div class="starsec"></div>
        <div class="starthird"></div>
        <div class="starfourth"></div>
        <div class="starfifth"></div>
    </div>
    <!--Dust particle end--->
	<div class="container">
		<div class="row">	
            <div class="col-sm-12">
                <h1 class="text-center heading">404</h1>
                <div class="animation"></div>
                <h3 class="text-center">
                    Look like you're lost
                </h3>
                <div class="text-center">
                    <p>The page you are looking for not avaible!</p>
                    
                    <a href="/dashboard" class="link_404">Go to Home</a>
                </div>
            </div>
		</div>
	</div>
</section>